/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:1bc65925-45a6-46db-9482-82d539c0eebc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_d5Cf4v6fT",
    "aws_user_pools_web_client_id": "70pr0ngk6caicffj27lc018oat",
    "oauth": {
        "domain": "rvpanel-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://express.realvirtual.com.mx/login/callback/",
        "redirectSignOut": "https://express.realvirtual.com.mx/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
