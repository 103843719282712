/* eslint-disable global-require */
/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import {
  // isMultiColorActive,
  defaultColor,
  // isDarkSwitchActive,
} from './constants/defaultValues';
import { /*getCurrentColor, */setCurrentColor } from './helpers/Utils';
import Amplify from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

// const color = (isMultiColorActive || isDarkSwitchActive) ? getCurrentColor() : defaultColor;
const color = defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render(); 
